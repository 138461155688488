<template>
  <div class="Test">
    <h1>加密解密的KEY</h1>
    <el-input
      placeholder="请输入内容"
      v-model="key"
      clearable>
    </el-input>
    <div class="cards">
      <el-card shadow="always" class="card">
        <div slot="header" class="clearfix">
          <span class="title">加密测试</span>
          <div class="card-btns">
            <el-button @click="onEncryptClick" type="text">加密</el-button>
          </div>
        </div>
        <div class="card-content">
          <el-input
            class="textarea"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder="请输入内容"
            v-model="text">
          </el-input>

          <el-input
            class="textarea"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder=""
            v-model="text2">
          </el-input>
        </div>
      </el-card>

      <el-card shadow="always" class="card">
        <div slot="header" class="clearfix">
          <span class="title">解密测试</span>
          <div class="card-btns">
            <el-button @click="onDecryptClick" type="text">解密</el-button>
          </div>
        </div>
        <div class="card-content">
          <el-input
            class="textarea"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder="请输入内容"
            v-model="text21">
          </el-input>

          <el-input
            class="textarea"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder=""
            v-model="text22">
          </el-input>
        </div>
      </el-card>
    </div>
    <div v-if="refresh"></div>
  </div>
</template>

<script>
import encryptUtil from '@/utils/encrypt.js'
export default {
  name: 'Help',
  data() {
    return {
      refresh: false,
      text: '测试加密内容',
      text2: '',
      text21: 'wG4UiTZM+TVBLuJRqWiRDDMbdC3YW/l46jSBtpP0ObI=',
      text22: '',
      key: 'asdf123',
    }
  },
  created (opt) {
    //JSEncrypt
    this.key = this.global.encrypt_key
  },
  mounted(){

  },
  methods: {
    onEncryptClick() {
      this.text2 = encryptUtil.encrypt(this.text, this.key)
      this.text21 = this.text2
      this.refresh = !this.refresh
    },
    onDecryptClick() {
      this.text22 = encryptUtil.decrypt(this.text21, this.key)
      this.refresh = !this.refresh
    }
  }
}
</script>

<style scoped lang='scss'>
.Test {
  margin-top: 0px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}
.card{
  width: 45em;
  margin: 10px;

  .clearfix{
    text-align: left;

    .title {
      font-size: 1.2em;
      font-weight: 700;
    }

    .card-btns{
      float: right;
      padding: 3px 0;
    }
  }
  .textarea{
    margin-top: 10px;
  }
  .textarea:first-child{
    margin-top: 0px;
  }
}
</style>

